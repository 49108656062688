import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const { REACT_APP_API_URL } = process.env;
function Modal({ isVisible, onClose, setCreatedPayin }: any) {
  const navigate = useNavigate();
  const [validateEntries, setValidateEntries] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      transactionReference: "",
      currency: "",
      ledgerTransactionReference: "",
      amount: "",
      senderName: "",
      accountNumber: "",
      senderAccountNumber: "",
      bank: "",
      status: "",
      transactionDate: "",
    },
    validate: function (values) {
      const errors: {
        transactionReference?: string;
        currency?: string;
        ledgerTransactionReference?: string;
        amount?: string;
        senderName?: string;
        accountNumber?: string;
        senderAccountNumber?: string;
        bank?: string;
        status?: boolean;
        transactionDate?: string;
      } = {};
      if (values.transactionReference.length < 12) {
        errors.transactionReference = "Reference must be 12";
        setValidateEntries(true);
      } else if (values.transactionReference.length > 12) {
        errors.transactionReference = "Reference must not be more than 12";
        setValidateEntries(true);
      } else if (!values.transactionReference) {
        errors.transactionReference = "Required!";
        setValidateEntries(true);
      } else if (!values.currency) {
        errors.currency = "Required!";
        setValidateEntries(true);
      } else if (values.ledgerTransactionReference.length < 12) {
        errors.ledgerTransactionReference = "Reference must be 12";
        setValidateEntries(true);
      } else if (values.ledgerTransactionReference.length > 12) {
        errors.ledgerTransactionReference =
          "Reference must not be more than 12";
        setValidateEntries(true);
      } else if (!values.ledgerTransactionReference) {
        errors.ledgerTransactionReference = "Required!";
        setValidateEntries(true);
      } else if (!values.amount) {
        errors.amount = "Required!";
        setValidateEntries(true);
      } else if (!values.senderName) {
        errors.senderName = "Required!";
        setValidateEntries(true);
      } else if (!values.accountNumber && !values.senderAccountNumber) {
        errors.accountNumber = "Required!";
        errors.senderAccountNumber = "Required!";
        setValidateEntries(true);
      } else if (!values.bank) {
        errors.bank = "Required!";
        setValidateEntries(true);
      } else if (!values.transactionDate) {
        errors.transactionDate = "Required!";
        setValidateEntries(true);
      } else {
        setValidateEntries(false);
      }
      return errors;
    },

    onSubmit: async (values) => {
      const authentication = JSON.parse(sessionStorage.getItem("token"));
      console.log(authentication.token);
      console.log(values);
      try {
        setLoading((prev) => (prev = true));
        const res = await axios.post(
          `${REACT_APP_API_URL}/ledger/services/admin/payins`,
          JSON.stringify(values),
          {
            headers: {
              "X-Auth-Token": authentication.token,
              "content-type": "application/json",
              Accept: "application/json",
            },
          }
        );
        if (res.status === 200) {
          toast.success("Payins Created Successfully");
          values.transactionReference = "";
          values.currency = "";
          values.ledgerTransactionReference = "";
          values.amount = "";
          values.senderName = "";
          values.senderAccountNumber = "";
          values.accountNumber = "";
          values.bank = "";
          values.transactionDate = "";
          values.status = "";
          setLoading((prev) => (prev = false));
          setCreatedPayin(true);
          onClose();
        }
      } catch (error: any) {
        const theError = error.response.data.message || error.message;
        toast.error(theError);
        if (theError.toLowerCase() === "token could not be verified") {
          sessionStorage.removeItem("token");
          navigate("/auth");
          toast.warning("Token Expired, Please login again")
          return;
        }
        setLoading((prev) => (prev = false));
        // values.transactionReference = "";
        // values.currency = "";
        // values.ledgerTransactionReference = "";
        // values.amount = "";
        // values.senderName = "";
        // values.senderAccountNumber = "";
        // values.accountNumber = "";
        // values.bank = "";
        // values.transactionDate = "";
        // values.status = "";
      }
      // onClose();
    },
  });

  if (!isVisible) return null;

  const handleClose = (e: any) => {
    if (e.target.id === "wrapper") onClose();
  };

  return (
    <div
      className="fixed inset-0 mt-28 flex
     items-center justify-center bg-opacity-25 backdrop-blur-sm "
      id="wrapper"
      onClick={handleClose}
    >
      <div className="flex h-[80%] w-[500px] flex-col overflow-auto rounded bg-white p-2 md:ml-20 md:w-[850px]">
        <div className="mt-1 flex items-center justify-end">
          <button
            className="rounded-full border-2 bg-brandLinear p-1 text-[10px] text-white disabled:cursor-not-allowed disabled:bg-brand-200 dark:disabled:bg-brand-200"
            onClick={() => onClose()}
          >
            X
          </button>
        </div>
        <div className="m-3">
          <h3 className="font-[Monrope] text-2xl font-bold">Create Payin</h3>
        </div>
        <form
          className="mb-4 rounded bg-white px-8 pb-8 pt-6"
          onSubmit={formik.handleSubmit}
        >
          <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Reference Number*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="transactionReference"
                name="transactionReference"
                type="number"
                placeholder="Reference Number"
                onChange={formik.handleChange}
                value={formik.values.transactionReference}
              />
              {formik.errors.transactionReference && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.transactionReference}
                </p>
              )}
            </div>

            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Currency*
              </label>
              <select
                className="block w-full appearance-none rounded border px-4 py-3 pr-8 leading-tight text-gray-700 shadow focus:border-gray-500 focus:bg-white focus:outline-none"
                id="currency"
                name="currency"
                value={formik.values.currency}
                onChange={formik.handleChange}
              >
                <option>select</option>
                <option>EURO</option>
                <option>USDX</option>
                <option>POUNDS</option>
              </select>

              {formik.errors.currency && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.currency}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Transaction Reference*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="ledgerTransactionReference"
                name="ledgerTransactionReference"
                type="number"
                placeholder="Transaction Reference"
                value={formik.values.ledgerTransactionReference}
                onChange={formik.handleChange}
              />
              {formik.errors.ledgerTransactionReference && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.ledgerTransactionReference}
                </p>
              )}
            </div>
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Amount*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="amount"
                name="amount"
                type="number"
                placeholder="Amount"
                onChange={formik.handleChange}
                value={formik.values.amount}
              />
              {formik.errors.amount && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.amount}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Sender Name*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="senderName"
                name="senderName"
                type="text"
                placeholder="Sender Name"
                value={formik.values.senderName}
                onChange={formik.handleChange}
              />
              {formik.errors.senderName && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.senderName}
                </p>
              )}
            </div>
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Gird Account Number*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="accountNumber"
                name="accountNumber"
                type="text"
                placeholder="Send Account Number/IBAN"
                value={formik.values.accountNumber}
                onChange={formik.handleChange}
              />
              {formik.errors.accountNumber && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.accountNumber}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Sender Bank Name*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="bank"
                name="bank"
                type="text"
                placeholder="Sender Bank Name"
                value={formik.values.bank}
                onChange={formik.handleChange}
              />
              {formik.errors.bank && (
                <p className="text-[13px] text-red-500">{formik.errors.bank}</p>
              )}
            </div>
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Sender Account Number/IBAN*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="senderAccountNumber"
                name="senderAccountNumber"
                type="text"
                placeholder="Send Account Number/IBAN"
                value={formik.values.senderAccountNumber}
                onChange={formik.handleChange}
              />
              {formik.errors.senderAccountNumber && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.senderAccountNumber}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-3 md:flex-row">
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Transaction Date*
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                id="transactionDate"
                name="transactionDate"
                type="date"
                placeholder="Transaction Date"
                value={formik.values.transactionDate}
                onChange={formik.handleChange}
              />
              {formik.errors.transactionDate && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.transactionDate}
                </p>
              )}
            </div>
            <div className="mb-4 w-full">
              <label className="mb-2 block text-sm font-bold text-gray-700">
                Status*
              </label>
              <select
                className="block w-full appearance-none rounded border px-4 py-3 pr-8 leading-tight text-gray-700 shadow focus:border-gray-500 focus:bg-white focus:outline-none"
                id="currency"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              >
                <option value="">Select</option>
                <option>APPROVED</option>
                <option>PENDING</option>
              </select>

              {formik.errors.status && (
                <p className="text-[13px] text-red-500">
                  {formik.errors.status}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none disabled:cursor-not-allowed disabled:bg-brand-200 dark:disabled:bg-brand-200"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="mr-3 inline h-4 w-4 animate-spin text-white"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </>
              ) : (
                "Create Payin"
              )}
            </button>
          </div>
        </form>
      </div >
    </div >
  );
}

export default Modal;
