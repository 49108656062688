import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Checkbox from "components/checkbox";
import { useState, useContext } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserAuthContext } from "contexts/auth/UserAuthContext";
import UserAuthActions from "actions/UserAuthActions";

const { REACT_APP_API_URL } = process.env
export default function SignIn() {
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordToggleState, setPasswordToggleState] = useState<
    "password" | "text"
  >("password");
  const [validateEntries, setValidateEntries] = useState<boolean>(true);
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  const { state, dispatch } = useContext(UserAuthContext);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate: function (values) {
      const errors: { email?: string; password?: string } = {};
      if (!values.email) {
        errors.email = "Required!";
        setValidateEntries(true);
      } else if (
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(
          values.email
        )
      ) {
        errors.email = "Invalid email format!";
        setValidateEntries(true);
      } else if (values.password.length < 6) {
        errors.password = "Password must be 6!";
        setValidateEntries(true);
      } else if (values.password.length > 15) {
        errors.password = "Password must not be more than 6!";
        setValidateEntries(true);
      } else if (!values.password) {
        errors.password = "Required!";
        setValidateEntries(true);
      } else {
        setValidateEntries(false);
      }
      return errors;
    },
    onSubmit: async (values) => {
      try {
        setLoading((prev) => (prev = true));
        const res = await axios.post(
          `${REACT_APP_API_URL}/users/auth/admin-login`,
          JSON.stringify(values),
          {
            headers: {
              "content-type": "application/json",
              // 'Access-Control-Allow-Origin': "*"
            },
          }
        );
        sessionStorage.setItem("email", values.email);
        dispatch({
          type: UserAuthActions.addEmailCredentials,
          payload: sessionStorage.getItem("email"),
        });
        setLoading((prev) => (prev = false));
        console.log(res, "res")
        toast.success(res.data.message);
        if (res.status === 200)
          navigate("signin/otp", { state: { email: values.email } });
      } catch (error: any) {
        const theError = error.config.message || error.message;
        toast.error(theError);
        values.email = "";
        values.password = "";
        setLoading((prev) => (prev = false));
      }
    },
  });
  const _togglePasswordVisibilityOnClick = () => {
    setHidePassword((prev) => !prev);
    hidePassword === true
      ? setPasswordToggleState("password")
      : setPasswordToggleState("text");
  };
  const ErrorAlert = (props: { errorMessage: string }) => (
    <div
      className="mb-4 flex items-center rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400"
      role="alert"
    >
      <svg
        className="mr-3 inline h-4 w-4 flex-shrink-0"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <span className="sr-only">Info</span>
      <div>
        <span className="font-medium">{props.errorMessage}</span>
      </div>
    </div>
  );
  return (
    <div className="flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-12 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <div className="rounded-full text-xl">
            <FcGoogle />
          </div>
          <h5 className="text-sm font-medium text-navy-700 dark:text-white">
            Sign In with Google
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center  gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        {/* Email */}

        <form action="#0" autoComplete="off" onSubmit={formik.handleSubmit}>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@simmmple.com"
            id="email"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.email.trim()}
          />
          {formik.errors.email && (
            <ErrorAlert errorMessage={formik.errors.email} />
          )}
          {/* Password */}
          <div className="relative">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password*"
              placeholder="Min. 8 characters"
              id="password"
              type={passwordToggleState}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <div
              className="absolute right-[0.9rem] top-[2.8rem] z-10 cursor-pointer !bg-white dark:!bg-navy-900"
              onClick={() => _togglePasswordVisibilityOnClick()}
            >
              {passwordToggleState === "password" ? (
                <AiOutlineEyeInvisible
                  className="text-navy-700 dark:text-white"
                  size={24}
                />
              ) : (
                <AiOutlineEye
                  className="text-navy-700 dark:text-white"
                  size={24}
                />
              )}
            </div>
          </div>
          {formik.errors.password && (
            <ErrorAlert errorMessage={formik.errors.password} />
          )}
          <button
            className="linear mt-2 w-full cursor-pointer rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 dark:disabled:bg-brand-200"
            type="submit"
            disabled={validateEntries || loading}
          >
            {loading ? (
              <>
                <svg
                  aria-hidden="true"
                  role="status"
                  className="mr-3 inline h-4 w-4 animate-spin text-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                Loading...
              </>
            ) : (
              "Sign In"
            )}
          </button>
          {/* <div className="mt-4">
            <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
              Not registered yet?
            </span>
            <a
              href=" "
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            >
              Create an account
            </a>
          </div> */}
        </form>
      </div>
    </div>
  );
}
