import UserAuthActions from "actions/UserAuthActions";

export const UserAuthState: { email: string, tokenCredentials: any[] } = {
  email: "",
  tokenCredentials: [],
}

type actionTypes = {
  type: string,
  payload: any
}

const UserAuthReducer = (state = UserAuthState, { type, payload }: actionTypes) => {
  const { addTokenCredentials, addEmailCredentials } = UserAuthActions
  switch (type) {

    case addTokenCredentials:
      state.tokenCredentials.push(payload);
      return { ...state, };
      case addEmailCredentials:
        state.email = payload
        return { ...state, email: payload }

    default:
      return state
  }
}

export default UserAuthReducer
