import React, { useReducer } from "react";
import { createContext } from "react"
import UserAuthReducer, { UserAuthState } from "../../reducers/auth/UserAuthReducer";

export const UserAuthContext = createContext(null)

const UserAuthContextProvider = (props: { children: JSX.Element }) => {
    const { children } = props;
    const [state, dispatch] = useReducer(UserAuthReducer, UserAuthState);
    return (
        <UserAuthContext.Provider value={{state, dispatch}}>
            {children}
        </UserAuthContext.Provider>
    )
}

export default UserAuthContextProvider