import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/auth/auth.svg";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import routes from "routes";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "views/auth/SignIn";

export default function Auth() {
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex flex-col">
            <div className="mx-auto flex lg:flex-row flex-col min-h-full w-full justify-between pt-12 md:max-w-[75%] lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="flex pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full basis-1/2 lg-max:mb-12">
                {/* <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes> */}
                <SignIn />
              </div>
              {/* <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px]"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
              </div> */}
              <div className="lg:w-[49vw] lg-max:hidden 2xl:basis-1/2 h-full">
                <img
                  className="bg-cover lg:rounded-bl-[120px] xl:rounded-bl-[200px] h-full"
                  src={authImg}
                />
              </div>
            </div>
              <Footer />
          </div>
        </main>
      </div >
    </div >
  );
}
