import { useEffect, useState } from "react";
import tableDataDevelopment from "./variables/tableDataDevelopment";
import axios from "axios";
import tableDataCheck from "./variables/tableDataCheck";
import CheckTable from "./components/CheckTable";
import tableDataColumns from "./variables/tableDataColumns";
import tableDataComplex from "./variables/tableDataComplex";
import DevelopmentTable from "./components/DevelopmentTable";
import ColumnsTable from "./components/ColumnsTable";
import ComplexTable from "./components/ComplexTable";
import { UserAuthContext } from "contexts/auth/UserAuthContext";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";

const { REACT_APP_API_URL } = process.env;
const Tables = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true)
  const [payoutData, setPayoutData] = useState<any | null>(null)
  const { token } = JSON.parse(sessionStorage.getItem("token"));
  console.log(token)
  useEffect(() => {
    async function getData() {

      try {
        const option = {
          method: "GET",
          headers: {
            "X-Auth-Token": token,
            "Content-Type": "application/json",
            "Accept": "application/json",
          },
        };
        const res = await fetch(
          `${REACT_APP_API_URL}/ledger/services/admin/payouts`,
          option
        );
        const result = await res.json();
        setPayoutData((prev: {} | []) => prev = result)
        setLoading(prev => prev = false);
        payoutData && toast.success("Fetched Successfully");
      } catch (error: any | {}) {
        console.error(error);
        setLoading(prev => prev = false);
        toast.error(error.message)
      }
    }
    getData()
  }, [])

  console.log(payoutData)
  if (payoutData?.code == "UNAUTHENTICATED" || payoutData?.message == "Token could not be verified") {
    sessionStorage.removeItem("token");
    navigate("/auth");
    toast.warning("Token Expired, Please login again")
    return;
  }
  return (
    loading ?
      <div className="flex w-full min-h-[84vh] justify-center items-center">
        <Loader />
      </div> :
      payoutData === null ? (
        <div>
          <p>ERROR</p>
          <p>ERROR</p>
          <p>ERROR</p>
          <p>ERROR</p>
          <p>ERROR</p>
          <p>ERROR</p>
          <p>ERROR</p>
        </div>
      )
        : (
          <div>
            <div className="mt-5 h-full gap-5">
              <DevelopmentTable tableData={[payoutData]} token={token} />
            </div >
          </div >)
  )
};

export default Tables;
