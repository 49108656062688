import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import OTP from "views/auth/SignIn/OTP";
import NotFoundPage from "views/404";
import Authenticated from "views/auth/components/Authenticated";
const App = () => {
  return (
    <Routes>
      <Route element={<Authenticated />}>
        <Route path="admin" element={<AdminLayout />} />
        <Route path="admin/*" element={<AdminLayout />} />
        {/* <Route path="auth" element={!!sessionStorage.getItem("token") ? <AuthLayout /> : <AdminLayout />} /> */}
        <Route path="/" element={<AdminLayout />} />
      </Route>
        <Route path="auth" element={<AuthLayout />} />
      <Route path="auth/signin/otp" element={<OTP />} />
      {/* <Route path="auth/otp" element={<Navigate to="/auth" replace />} /> */}
      <Route path="*" element={<NotFoundPage />} />
        {/* <Route path="admin/*" element={<Authenticated>
          <AdminLayout />
          </Authenticated>
        } /> */}
    </Routes>
  );
};

export default App;
