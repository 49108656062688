import React from "react";

// Admin Imports
import NFTMarketplace from "views/admin/Payins";
import PayOuts from "views/admin/payouts";
import PayOut from "views/admin/payouts/payout";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
} from "react-icons/md";

const routes = [
  {
    name: "Payins",
    layout: "/admin",
    path: "payins",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "PayOuts",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "payouts",
    component: <PayOuts />,
  },
  {
    name: "PayOut",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "payouts/payout/:payoutId",
    component: <PayOut />,
  },
];
export default routes;
