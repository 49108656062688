import { useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserAuthContext } from "contexts/auth/UserAuthContext";
import UserAuthActions from "actions/UserAuthActions";
import OTPInput from "react-otp-input";
  
const { REACT_APP_API_URL } = process.env
export default function OTP() {
  // const { state: { email } } = useLocation();
  const [otp, setOtp] = useState("");
  // const [token, setToken] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const { state, dispatch } = useContext(UserAuthContext);

  // const history;
  const inputProps = {
    variant: "auth",
    extra: "w-12 h-12 text-lg",
    type: "text",
  };
  const inputExtraProps = {
    maxLength: 1,
    autoComplete: "false",
  };
  const navigate = useNavigate();
  const inputsRef = useRef(null);
  const handleOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading((prev) => (prev = true));
      const data = {
        email: state.email || sessionStorage.getItem("email"),
        token: otp,
      };
      const option = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(data),
      };
      const res = await fetch(
        `${REACT_APP_API_URL}/users/auth/admin/otp/verify`,
        option
      );
      const result = await res.json();
      setLoading((prev) => (prev = false));
      if (result.code === "ERR_BAD_REQUEST") {
        toast.error(result.data.message || result.message);
      } else {
        sessionStorage.setItem("token", JSON.stringify(result));
        dispatch({
          type: UserAuthActions.addTokenCredentials,
          payload: JSON.parse(sessionStorage.getItem("token")),
        });
        toast.success("Successfully Logged in");
        navigate("/admin", { replace: true });
      }
    } catch (error: any) {
      setLoading((prev) => (prev = false));
      const theError =
        error.message ||
        error.data.message ||
        error.response.data.message ||
        error.response.message ||
        error;
      setLoading((prev) => (prev = false));
      console.error(error, "error");
      toast.error(theError || "The OTP token does not exist for user");
    }
  };
  return (
    <section className="mx-auto flex h-screen w-full max-w-[1200px] items-center justify-center">
      <form className="mx-auto max-w-[1200px] text-center" onSubmit={handleOtp}>
        <h1 className="mb-2.5 text-4xl font-bold text-navy-700">
          Verification Code
        </h1>
        <p className="mb-6 ml-1 text-base text-gray-600">
          A code was sent to you mail <strong>{state.email}</strong>. <br />
          Please check your inbox/spam and enter the code below
        </p>
        <div>
          <div
            id="otpInputs"
            className="mb-10 flex justify-center gap-4"
            ref={inputsRef}
          >
            <OTPInput
              containerStyle="flex gap-4 justify-between mb-10"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              inputStyle="mt-2 flex h-12 !w-12 items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:text-navy-700 focus:border-brand-500 dark:border-navy-700"
              renderInput={(props) => <input {...props} />}
              shouldAutoFocus={true}
            />
          </div>
        </div>
        <div className="mt-4">
          <span className=" text-md font-medium text-navy-700 dark:text-gray-600">
            Didn't get a code?
          </span>
          <a
            href=" "
            className="text-md ml-1 font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Resend
          </a>
        </div>
        <button
          className="linear mt-2 w-full cursor-pointer rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 disabled:cursor-not-allowed disabled:bg-brand-200 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 dark:disabled:bg-brand-200"
          disabled={otp.length !== 6 || loading}
          type="submit"
        >
          Verify OTP
        </button>
      </form>
    </section>
  );
}
