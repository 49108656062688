import React from "react";
import { useRoutes, Route, useHref, useLocation } from "react-router-dom";
import tableDataDevelopment from "../variables/tableDataDevelopment";
import {
  createColumnHelper,
} from "@tanstack/react-table";
import ColumnsTable from "../components/ColumnsTable";
import tableDataColumns from "../variables/tableDataColumns";

function PayOut() {
  const { state: { singlePayout } } = useLocation();
  console.log(singlePayout, "data")
  // const {data} = state
  // const routeId = String(location.pathname.split("/").slice(-1));
  // const objectBelongingToRouteId = tableDataDevelopment.find(item => item.reference.toLowerCase().endsWith(routeId));
  // console.log(objectBelongingToRouteId)
  return (
    <>
      <ColumnsTable tableData={singlePayout} />
    </>
  )
}

export default PayOut;
