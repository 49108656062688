import React, { useEffect, useState } from 'react'
import Read from './Read';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from 'components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';

const { REACT_APP_API_URL } = process.env;
function PayinPage({ data }: any) {
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [payinData, setPayinData] = useState(data);

  const { pathname } = useLocation();
  const navigate = useNavigate()
  useEffect(() => {
    setPayinData(data)
    setLoading(prev => prev = false)
  }, [data])

  // console.log(payinData, "data")
  const pageSize = payinData.pageSize;
  let pageNumber = payinData.pageNumber;
  const totalPages = payinData.totalPages;
  const records = payinData.payInTransactions;
  const totalRecords = payinData.totalPayInTransactions;
  // const nPage = Math.ceil(payinData.payInTransactions?.length / pageSize);
  // const numbers = [...Array(nPage + 1).keys()].slice(1);
  const numbers = {}

  // function prevPage() {
  //   if (pageNumber !== 1) {
  //     pageNumber = pageNumber - 1;
  //   }
  // }
  // function changeCPage(id: any) {
  //   pageNumber = id;
  // }
  // function nextPage() {
  //   if (pageNumber !== totalPages) {
  //     pageNumber = pageNumber + 1;
  //   }
  // }

  const [modalItem, setModalItem] = useState();
  const paginationStyles = "text-2xl w-6 h-6 hover:bg-gray-200 rounded-full cursor-pointer text-gray-700";
  const _previousPagination = () => {
    setLoading(prev => prev = true)
    const authentication = JSON.parse(sessionStorage.getItem("token"));
    console.log(authentication.token);
    const option = {
      headers: {
        "X-Auth-Token": authentication.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    axios
      .get(
        `${REACT_APP_API_URL}/ledger/services/admin/payins/?page=${(pageNumber - 1)}`,
        option
      )
      .then((res) => {
        console.log(res.data);
        setLoading(prev => prev = false);
        setPayinData(res.data);
        // window.history.replaceState(null, "New Set of Table Data", `${pathname}/p${(pageNumber + 1) < totalPages ? pageNumber + 1 : totalPages}`)
      })


      .catch((err) => {
        toast.error(err.message)
        console.log(err)
        setLoading(prev => prev = false)
      });
    console.log(data, 'data');
  }
  const _nextPagination = () => {
    setLoading(prev => prev = true)
    const authentication = JSON.parse(sessionStorage.getItem("token"));
    console.log(authentication.token);
    const option = {
      headers: {
        "X-Auth-Token": authentication.token,
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    };

    axios
      .get(
        `${REACT_APP_API_URL}/ledger/services/admin/payins/?page=${(pageNumber + 1) < totalPages ? pageNumber + 1 : totalPages}`,
        option
      )
      .then((res) => {
        console.log(res.data);
        setLoading(prev => prev = false);
        setPayinData(res.data);
        // window.history.replaceState(null, "New Set of Table Data", `${pathname}/p${(pageNumber + 1) < totalPages ? pageNumber + 1 : totalPages}`)
      })


      .catch((err) => {
        toast.error(err.message)
        console.log(err)
        setLoading(prev => prev = false)
      });
    console.log(data, 'data');
  }
  return (
    <>
      {modalItem && <Read isVisible={showModal} onClose={() => { setShowModal(false); setModalItem(prev => prev = null) }} item={modalItem} />}
      <div>
        <div className="w-full overflow-auto border-2 shadow-lg">
          <div className='flex justify-between border-b-2'>
            <h3 className="ml-5 py-3 text-[15px] font-bold flex items-center gap-x-4">
              All Business Payin Transaction{" "}
              <span className="text-blue-200">{totalRecords}</span>
            </h3>
            <div className="flex items-center gap-x-4 mr-8">
              <p className='text-gray-600'><span>{(pageSize * (pageNumber - 1)) + 1}-</span>{(pageSize * pageNumber) > totalRecords ? totalRecords : (pageSize * pageNumber)} of <span>{totalRecords}</span></p>
              <MdChevronLeft className={`${paginationStyles} ${pageNumber == 1 && "text-gray-100  opacity-50 cursor-not-allowed"}`} onClick={() => {
                if (pageNumber == 1) return;
                _previousPagination();
              }} />
              <MdChevronRight className={`${paginationStyles} ${pageNumber == totalPages && "text-gray-100  opacity-50 cursor-not-allowed"}`} onClick={() => {

                if (pageNumber == totalPages) return;
                _nextPagination()
              }} />
            </div>
          </div>
          {loading && <div className='w-full flex justify-center items-center'>
            <Loader />
          </div>}
          {!loading && <table className="w-full" id="tableID">
            <thead className="border-b-2 bg-blue-100">
              <tr className="m-2 text-left text-[14px] uppercase text-blue-300">
                <th className="px-4 py-3 font-bold">Reference</th>
                <th className="px-4 py-3 font-bold">Currency</th>
                <th className="px-4 py-3 font-bold">Reference</th>
                <th className="px-4 py-3 font-bold">Amount</th>
                <th className="px-4 py-3 font-bold">Sender Name</th>
                <th className="px-4 py-3 font-bold">Transaction Date</th>
                <th className="px-4 py-3 font-bold">Status</th>
              </tr>
            </thead>
            <tbody>
              {records?.map((item: any, i: any) => {
                return (
                  <tr
                    key={i}
                    className="cursor-pointer border-b-2 hover:bg-gray-200"
                    onClick={() => { setShowModal(true); setModalItem(prev => prev = item) }}
                  >
                    <td className="w-1/2 px-4 py-3 text-blue-500 text-[14px]">
                      {item.transactionReference}
                    </td>
                    <td className="px-4 py-3 text-blue-500">{item.currency}</td>
                    <td className="px-4 py-3 w-1/2 text-[14px]">{item.ledgerTransactionReference}</td>
                    <td className="px-4 py-3">{item.amount}</td>
                    <td className="px-4 py-3">{item.senderName}</td>
                    <td className="px-4 py-3">{item.transactionDate}</td>
                    <td className="px-4 py-3">{item.status}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>}
        </div>
        {/* <nav className="flex justify-end">
        <ul className="mt-8 inline-flex -space-x-px text-sm shadow-lg">
          <li>
            <a
              href="#"
              onClick={prevPage}
              className="ml-0 flex h-8 items-center justify-center rounded-l-lg border
       border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100
        hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400
         dark:hover:bg-gray-700 dark:hover:text-white"
            >
              Previous
            </a>
          </li>

         {numbers.map((n, i) => (
            <li key={i}>
              <a
                href="#"
                aria-current="page"
                onClick={() => changeCPage(n)}
                className="flex h-8 items-center justify-center border border-gray-300 bg-blue-50
         px-3 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700
          dark:bg-gray-700 dark:text-white"
              >
                {n}
              </a>
            </li>
          ))}

          <li>
            <a
              href="#"
              onClick={nextPage}
              className="flex h-8 items-center justify-center rounded-r-lg border
       border-gray-300 bg-white px-3 leading-tight text-gray-500 hover:bg-gray-100
        hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400
         dark:hover:bg-gray-700 dark:hover:text-white"
            >
              Next
            </a>
          </li>
        </ul>
      </nav> */}
      </div >
    </>
  );
}

export default PayinPage