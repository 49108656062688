import React, { useState } from "react";


interface UserProps {
  isVisible: boolean;
  onClose: () => void;
  item: any | {};
}

function Read({ isVisible, onClose, item }: UserProps) {

  const [readData, setReadData] = useState(item);

  console.log(readData, "read");

  if (!isVisible) return null;

  const handleClose = (e: any) => {
    if (e.target.id === "wrapper") onClose();
  };

  const detailsStyles = "mb-5 border-b flex justify-between";
  return (
    <div
      className="bg-black fixed inset-0 mt-28 flex
     items-center justify-center bg-opacity-25 backdrop-blur-sm dark:bg-navy-900 "
      id="wrapper"
      onClick={handleClose}
    >
      <div className="flex h-[70%] w-[500px] flex-col overflow-auto rounded bg-white p-2 md:ml-20 md:w-[850px]">
        <div className="mt-1 flex items-center justify-end">
          <button
            className="rounded-full border-2 bg-brandLinear p-1 text-[10px] text-white"
            onClick={() => onClose()}
          >
            X
          </button>
        </div>
        <div className="m-5 p-5">
          <div className="mb-5">
            <p className="front-bold text-[25px]">Payin Details</p>
          </div>
          <div className={detailsStyles}>
            <h2>Accont Number</h2>
            <p>{readData.accountNumber}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Reference</h2>
            <p>{readData.transactionReference}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Currency</h2>
            <p>{readData.currency}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Ledger Reference</h2>
            <p>{readData.ledgerTransactionReference}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Amount</h2>
            <p>{readData.amount}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Bank</h2>
            <p>{readData.bank}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Sender Name</h2>
            <p>{readData.senderName}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Status</h2>
            <p>{readData.status}</p>
          </div>
          <div className={detailsStyles}>
            <h2>Transaction Date</h2>
            <p>{readData.transactionDate}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Read;
