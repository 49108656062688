import ReactDOM from "react-dom/client";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from "react-router-dom";

import "./index.css";

import App from "./App";
import UserAuthContextProvider from "contexts/auth/UserAuthContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <UserAuthContextProvider>
    <BrowserRouter>
      <ToastContainer
        autoClose={1500}
      />
      <App />
    </BrowserRouter>
  </UserAuthContextProvider>
);
