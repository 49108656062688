

import { useState, useLayoutEffect, useRef } from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
export default function Authenticated(props: { children?: JSX.Element }) {
    // const { children } = props;
    // const [hasSession, setHasSession] = useState<undefined | boolean>(undefined);
    const hasSession = useRef(!!sessionStorage.getItem("token"))
    const location = useLocation();
    if (!!hasSession.current) {
        // if(location.pathname === "/auth") <Navigate to="admin" /> Trying to go to admin from login
        return <Outlet />
    }
    return (
        <>
            {/* ({toast.warning("Please log in first")} */}
            {/* <Navigate to="auth" state={{ from: location }} replace /> */}
            <Navigate to="auth" />
        </>)
}